import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg">
          <div className="onas">
            <div className="wrapper onas">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="section">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="link">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>
                  O značce&nbsp;
                  <br /> NEUTROGENA&nbsp;<span>®</span>
                </h2>
                <p>&nbsp;</p>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/neutrogena/o-nas/">NEUTROGENA®</a> <span>&gt;</span>
                  Norská receptura
                </div>
              </div>
            </div>
          </div>
          <div className="submenu">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/" className="link">
                O nás
              </a>
              <a href="/neutrogena/historie/" className="link">
                Historie
              </a>
              <a href="/neutrogena/norska-receptura/" className="current">
                Norská receptura
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <section>
                <div className="bg form1">
                  <div className="text full ">
                    <h2>Norská receptura</h2>
                    <p className="text right">
                      Obrat v historii společnosti způsobil výzkum ohledně
                      výživy a terapeutických vlastností glycerinu. Jeho
                      výjimečné působení bylo považováno za perfektní řešení pro
                      suchou a poškozenou pokožku.
                    </p>
                    <p className="text right">
                      <br />
                      Hlavním zdrojem inspirace pro vývoj této nové receptury
                      byly tradiční norské výrobky: tělová mléka a masti
                      používané norskými rybáři v arktických podmínkách. Díky
                      svému složení účinně snižovaly vysoušení, vznik ran a
                      prasklin na pokožce rukou.
                    </p>
                    <p className="text right">
                      <br />
                      Jako výsledek výzkumu a analýzy v extrémních
                      povětrnostních podmínkách, byla vyvinuta unikátní Norská
                      receptura.
                    </p>
                    <p className="text right">
                      <br />
                      Dokonale vyvážené složení zajišťuje:
                    </p>
                    <ul className="text right">
                      <li>účinnou péči</li>
                      <li>intenzivní hydrataci&nbsp;</li>
                      <li>
                        náležitou péči o pokožku vystavenou náročným
                        povětrnostním podmínkám.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section>
                <div className="bg form2">
                  <div className="text left">
                    <h2 className="text left">Receptura &nbsp; &nbsp;</h2>
                    <p className="text left">
                      <span>Norská </span>
                      <span>
                        receptura je charakteristická svým významným obsahem
                        glycerinu
                      </span>
                      <span>
                        . Tato speciální receptura proniká do vrchních vrstvy
                        epidermu, nabízí{" "}
                      </span>
                      <span>účinnou </span>
                      <span>hydrataci a pomáhá regeneraci pokožky.</span>
                    </p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg form3">
                  <div className="text right ">
                    <h2 className="text right">40 let péče o ruce</h2>
                    <p className="text right">
                      Tímto způsobem výrobky řady Norská receptura nejenže
                      redukují symptomy, ale také příčiny suché pokožky. Jejich
                      vysoká kvalita a účinnost byla prokázána dermatology,
                      kteří je doporučují svým pacientům. Výroba intenzivně
                      hydratujícího krému na ruce s obsahem Norské receptury -
                      krému na ruce NEUTROGENA<sup>®</sup> Norská receptura
                      classic - &nbsp;začala před 40 lety, v roce 1972. Dnes
                      zahrnuje svět výrobků NEUTROGENA<sup>®</sup> několik řad
                      produktů s obsahem ověřené a účinné Norské receptury
                      vyvinutých pro péči o ruce, nohy, tělo a rty.
                    </p>
                  </div>
                </div>
              </section>
              <div id="fb_66"></div>
          </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
